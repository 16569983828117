import React, { FC } from "react"
import { RouteComponentProps } from "@reach/router"
import { Button, Heading, Paragraph } from "../components"
import Logo from "../images/logo.svg"

const browser = typeof window !== "undefined" && window

const NotFoundPage: FC<RouteComponentProps> = () => {
  // Ensure that this 404 page doesn't render on shop-builder (/) routes
  // Source: https://github.com/gatsbyjs/gatsby/issues/5329#issuecomment-633123345
  if (!browser) return null

  return (
    <div className="flex flex-col items-center gap-6 pt-40 sm:gap-12 sm:pt-80">
      <Logo className="absolute left-5 top-6 w-10 lg:left-16 lg:top-14 lg:w-12" />
      <Heading level={1} align="center">
        Page not found (404)
      </Heading>
      <Paragraph level={1}>
        Head home to see if you can find your way.
      </Paragraph>
      <Button
        title="Take me home"
        onClick={() => {
          window.open("/", "_self")
        }}
      />
    </div>
  )
}

export default NotFoundPage
